import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiCalls } from "../DataService";

const RfiDataGrid = (params) => {
  // Server side MUI DataGrid stuff
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({ items:[] });
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState({ rows: [], totalCount: 0 })
  //const [resource, setResource] = useState();

  const loadData = async (resource, id) => {
    try {
      setLoading(true)
      const { rows, totalCount } = await apiCalls.getAllServer(resource, paginationModel, sortModel, filterModel);
      setRows({ rows: rows, totalCount: totalCount }) 
    } catch(err) {
      toast.error(`Unable to load ${resource}: ${err.message}`)
    } finally {
      setLoading(false)
    }
  }
  
  useEffect(() => {
    loadData(params.resource, params.rfiId)
  }, [paginationModel, filterModel, sortModel, params.resource])

  //loadData(params.resource)
  
  return (
    <DataGrid
      slots={{ toolbar: GridToolbar }}
      onPaginationModelChange={setPaginationModel}
      paginationModel={paginationModel}
      onFilterModelChange={setFilterModel}
      filterModel={filterModel}
      onSortModelChange={setSortModel}
      sortModel={sortModel}
      loading={loading}
      rows={rows.rows}
      rowCount={rows.totalCount}
      paginationMode="server"
      filterMode="server"
      sortingMode="server"
      density="compact"
      disableDensitySelector
      slotProps={{
        toolbar: {
          // API doesn't currently support quickFilterModel
          //showQuickFilter: true,
        },        
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
      }}      
      initialState={{
        columns: {
          columnVisibilityModel: { id: false },
        },
      }}
      pagination
      {...params}
    />
  )
}

export default RfiDataGrid
