import { useState, useEffect } from "react"

// Data
import { apiCalls } from "./components/DataService"

// Context
import AppContext from "./AppContext"

// Routes
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants"
import AppRoutes from "./AppRoutes"

// Query Library
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

// MUI
import { Box, ThemeProvider } from "@mui/material"

//Styles
import theme from "./theme"
import CssBaseline from "@mui/material/CssBaseline"
import "./css/index.css"

// MUI Licensing
import { LicenseInfo } from "@mui/x-license"
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE)

import { SnackbarProvider, enqueueSnackbar } from "notistack"

import { useAuth, hasAuthParams } from "react-oidc-context"
import axios from "axios"
import LoadingView from "./components/loading/LoadingView"

// SignalR for real-time messaging
// TODO: Improve this implementation and leverage react lifecycle
import * as signalR from "@microsoft/signalr"
import { ToastContainer } from "react-toastify"
const connection = new signalR.HubConnectionBuilder().withUrl("/notifications").configureLogging(signalR.LogLevel.Information).build()
connection.start()
connection.on("newMessage", (message, variant) => enqueueSnackbar(message, { variant: variant }))

const App = () => {
  // ** --------------***     [ ~ HOOKS ~ ]     ***-------------- **
  const [allOrganizations, setAllOrganizations] = useState("")
  const [allFiscalCycles, setAllFiscalCycles] = useState("")
  const [loading, setLoading] = useState(true)
  const [clickTarget, setClickTarget] = useState(null)
  const [selectedOrg, setSelectedOrg] = useState(undefined)
  const [classificationData, setClassificationData] = useState("")
  const [activeFiscalCycle, setActiveFiscalCycle] = useState(null)
  const [rfiOrgMapping, setRfiOrgMapping] = useState("")
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [filteredOrganizations, setFilteredOrganizations] = useState([])

  const handleOrgChange = (org) => {
    localStorage.setItem("ORG", JSON.stringify(org))
    setSelectedOrg(org)
  }

  const filterOrganizations = (orgList) => {
    const filteredOrgs = allOrganizations.filter((org) => orgList.includes(org.name))
    // TODO: if no organizations available (ie all the organizations user has access too aren't viable on this page) error out
    if (!filteredOrgs.some((org) => org.id === selectedOrg.id)) {
      handleOrgChange(filteredOrgs[0])
    }
    setFilteredOrganizations(filteredOrgs)
  }

  const excludeOrganizations = (orgList) => {
    const filteredOrgs = allOrganizations.filter((org) => !orgList.includes(org.name))
    // TODO: if no organizations available (ie all the organizations user has access too aren't viable on this page) error out
    if (!filteredOrgs.some((org) => org.id === selectedOrg.id)) {
      handleOrgChange(filteredOrgs[0])
    }
    setFilteredOrganizations(filteredOrgs)
  }

  const resetOrganizations = () => {
    // This shouldn't be needed but putting it in for safety
    if (!allOrganizations.some((org) => org.id === selectedOrg.id)) {
      handleOrgChange(filteredOrgs[0])
    }
    setFilteredOrganizations(allOrganizations)
  }

  // ** --------------***     [ ~ VARS ~ ]     ***-------------- **

  const context = {
    drawerOpen,
    setDrawerOpen,
    classificationData,
    clickTarget,
    allOrganizations,
    selectedOrg,
    handleOrgChange,
    allFiscalCycles,
    activeFiscalCycle,
    setActiveFiscalCycle,
    rfiOrgMapping,
    filteredOrganizations,
    filterOrganizations,
    excludeOrganizations,
    resetOrganizations,
  }

  // ** --------------***     [ ~ EFFECTS ~ ]     ***-------------- **

  // Rework auth
  const auth = useAuth()
  const [hasTriedSignin, setHasTriedSignin] = useState(false)
  // automatically sign-in
  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      auth.signinRedirect()
      setHasTriedSignin(true)
    }
    if (auth.isAuthenticated) {
      // Set Bearer token for all future API calls
      axios.defaults.headers.common["Authorization"] = `Bearer ${auth.user.access_token}`
      //loadAuth() // We shouldn't need this and instead should useAuth() throughout the app
    }
  }, [auth, hasTriedSignin])

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (classificationData && allOrganizations.length > 1) {
      allOrganizations.length > 1
      setLoading(false)
    }
  }, [classificationData, allOrganizations])

  // ** --------------***     [ ~ FUNCTIONS ~ ]     ***-------------- **

  const initOrg = (orgList) => {
    const data = JSON.parse(localStorage.getItem("ORG"))
    if (data != null) {
      // find in the all organizations array to ensure it is up to date
      setSelectedOrg(orgList.find((org) => org.id == data.id))
    } else {
      handleOrgChange(orgList[0])
    }
  }

  const getUser = async () => {
    if (sessionStorage.getItem("currentUser")) {
      return
    }
    try {
      let response = await apiCalls.fetchData("Accounts/me")
      if (response.status === 200) {
        sessionStorage.setItem("currentUser", JSON.stringify(response.data))
      }
    } catch (e) {
      console.log("e: ", e)
      window.location.replace(ApplicationPaths.IdentityLoginPath)
    }
  }

  const sortAlpha = (arr) => {
    return arr.sort((a, b) => a.name.localeCompare(b.name))
  }

  const init = async () => {
    getUser()

    let classFetch = apiCalls.fetchData("Configurations/Classification")
    let fiscalFetch = apiCalls.fetchData("FiscalCycles")
    let activeFiscalCycleFetch = apiCalls.fetchData("FiscalCycles/Active")
    let rfiMapFetch = apiCalls.fetchData("RfiOrganizationsMappings")
    let orgFetch = apiCalls.fetchData("Organizations")

    let [classResponse, fiscalResponse, activeFiscalCycleResponse, rfiMapResponse, orgResponse] = await Promise.all([
      classFetch,
      fiscalFetch,
      activeFiscalCycleFetch,
      rfiMapFetch,
      orgFetch,
    ])

    setRfiOrgMapping(rfiMapResponse.data)
    setAllFiscalCycles(fiscalResponse.data)
    setActiveFiscalCycle(activeFiscalCycleResponse.data)
    setClassificationData(classResponse.data)
    setAllOrganizations(orgResponse.data)
    setFilteredOrganizations(orgResponse.data)
    sortAlpha(orgResponse.data)
    initOrg(orgResponse.data)

    sessionStorage.setItem("classificationData", JSON.stringify(classResponse.data))
  }

  const queryClient = new QueryClient()

  if (auth.isLoading) {
    return <LoadingView />
  }

  if (!auth.isAuthenticated) {
    console.error(auth.error?.message)
    return <div>Unable to log in</div>
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>
  }
  // ** --------------***     [ ~ RENDER ~ ]     ***-------------- **
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContext.Provider value={context}>
        <SnackbarProvider
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        />
        <QueryClientProvider client={queryClient}>
          <Box
            className="appContainer"
            onClick={(e) => setClickTarget(e.target)}
          >
            {!loading && <AppRoutes loading={loading} />}
          </Box>

          {/* <div
              className="App"
              onClick={(e) => setClickTarget(e.target)}
            >
              {!loading && <AppRoutes loading={loading} />}
            </div> */}
          {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        </QueryClientProvider>
        <ToastContainer />
      </AppContext.Provider>
    </ThemeProvider>
  )
}

export default App
