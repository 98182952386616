import React, { useContext, useEffect, useState } from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, MenuItem, Select, TextField, InputLabel } from "@mui/material"
import "./AddOrganizationPOCModal.css"
import { apiCalls } from "../DataService"
import { OrganizationRoles } from "../../Utils"
import AppContext from "../../AppContext"

const AddOrganizationPOCModal = ({ isOpen, onClose, editParams, type, selectedOrg, currentPocList }) => {
  const [POCs, setPOCs] = useState([])
  const [formInput, setFormInput] = useState({
    poc: "",
    role: "",
  })
  const [showInputErrors, setShowInputErrors] = useState(false)
  const [POCError, setPOCError] = useState("")
  const [roleError, setRoleError] = useState("")
  const [selectedPOC, setSelectedPOC] = useState({})
  const [selectedRole, setSelectedRole] = useState("View Only")
  const [fullPocList, setFullPocList] = useState([])

  const handleChange = async (e, inputType) => {
    // TODO: There has got to be a better way to do this but this MR is getting large already.
    e.preventDefault()
    switch (inputType) {
      case "POC":
        const tempPOC = e.target.value
        setSelectedPOC({ ...tempPOC, role: selectedRole })
        setSelectedPOC((prevState) => {
          return {
            ...prevState,
          }
        })
        break
      case "role":
        setSelectedRole(e.target.value)
        setFormInput((prevState) => {
          return { ...prevState, role: e.target.value }
        })
        setSelectedPOC((prevState) => {
          return { ...prevState, role: e.target.value }
        })
        break
      default:
        break
    }
  }

  const handleSubmit = async (e, type) => {
    e.preventDefault()
    if (!POCError && !roleError) {
      // Make POST or PUT DB call to add or edit a POC
      setShowInputErrors(false)
      switch (type) {
        case "assign":
          // Add the POC to the selected organization
          await apiCalls.post(`Organizations/${selectedOrg.id}/Pocs/${selectedPOC.id}/${selectedRole}`, null)
          break
        case "edit":
          // Update the role of the POC link related to the selectedPOC that is associated with the selectedOrganization
          await apiCalls.put(`Organizations/${selectedOrg.id}/Pocs/${editParams.id}?role=${selectedRole}`, null)
          break
        default:
          break
      }
      onClose(true)
      setFormInput({
        poc: "",
        role: "",
      })
    } else setShowInputErrors(true)
  }

  const handleCancel = (e) => {
    e.preventDefault()
    setShowInputErrors(false)
    setFormInput({
      poc: "",
      role: "",
    })
    setSelectedPOC({})
    onClose(false)
  }

  //   useEffect(() => {
  //     // TODO: Fix Error Handling. Leaving this in but commented out (note, formInput isn't really being used correctly)
  //     // Error handling
  //     if (!formInput.poc.length && type === "assign") {
  //       setPOCError("* Please select a POC")
  //     } else setPOCError("")
  //     if (!formInput.role.length) {
  //       setRoleError("* Please select a role")
  //     } else setRoleError("")
  //   }, [formInput])

  useEffect(() => {
    if (fullPocList.length > 0) {
      const tempPOCs = fullPocList.filter((poc) => {
        // Make sure the POC doesn't already exist in the table
        const existsInTable = currentPocList ? currentPocList.map((p) => p.id).includes(poc.id) : false
        if (!existsInTable && poc.activeState) return true
        else return false
      })
      setPOCs(tempPOCs)
    }
  }, [currentPocList, fullPocList, setPOCs])

  useEffect(() => {
    apiCalls.getAll(`Pocs`, {}).then((data) => {
      setFullPocList(data)
    })
  }, [setFullPocList])

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
    >
      <DialogTitle>
        {type === "edit" ? (
          <div>
            Edit POC for {selectedOrg.name} - {selectedOrg.longName}
          </div>
        ) : (
          <div>
            Assign POC for {selectedOrg.name} - {selectedOrg.longName}
          </div>
        )}
      </DialogTitle>
      <DialogContent dividers>
        <form className="poc-form">
          <div className="poc-role">
            <div className="input-field">
              <InputLabel
                className="label"
                htmlFor="-POCs"
              >
                {type === "edit" ? (
                  <div>POC</div>
                ) : (
                  <div>
                    POC <span className="asterick">*</span>
                  </div>
                )}
              </InputLabel>
              {type === "assign" ? (
                <Select
                  name="POCs"
                  id="-POCs"
                  className="poc-input"
                  value="select-poc"
                  onChange={(e) => handleChange(e, "POC")}
                >
                  <MenuItem
                    value="select-poc"
                    hidden
                  >
                    Select
                  </MenuItem>
                  {POCs.length > 0 &&
                    POCs.map((poc) => (
                      <MenuItem
                        key={poc.id}
                        value={poc}
                      >
                        {poc.firstName} {poc.lastName}
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Select
                  name="POCs"
                  id="-POCs"
                  className="poc-input"
                  value="poc"
                  disabled
                >
                  <MenuItem value="poc">
                    {editParams.firstName} {editParams.lastName}
                  </MenuItem>
                </Select>
              )}

              {POCError.length > 0 && showInputErrors ? <p className="error-msg">{POCError}</p> : null}
            </div>
            <div className="input-field">
              <InputLabel
                className="label"
                htmlFor="role"
              >
                {type === "edit" ? (
                  <div>Organization Role</div>
                ) : (
                  <div>
                    Organization Role <span className="asterick">*</span>
                  </div>
                )}
              </InputLabel>
              <Select
                name="roles"
                id="-roles"
                className="poc-input"
                onChange={(e) => handleChange(e, "role")}
                value={type === "edit" ? editParams.role : "select-role"}
              >
                <MenuItem
                  value="select-role"
                  hidden
                >
                  Select
                </MenuItem>
                <MenuItem value={OrganizationRoles.VIEW_ONLY}>{OrganizationRoles.VIEW_ONLY}</MenuItem>
                <MenuItem value={OrganizationRoles.USER_ACCESS_DATA_LOADER}>{OrganizationRoles.USER_ACCESS_DATA_LOADER}</MenuItem>
                <MenuItem value={OrganizationRoles.FINAL_APPROVER}>{OrganizationRoles.FINAL_APPROVER}</MenuItem>
                <MenuItem value={OrganizationRoles.SUPER_USER}>{OrganizationRoles.SUPER_USER}</MenuItem>
              </Select>
              {roleError.length > 0 && showInputErrors ? <p className="error-msg">{roleError}</p> : null}
            </div>
          </div>
          <div className="fname-lname">
            <div className="input-field">
              <InputLabel
                className="label"
                htmlFor="first-name"
              >
                First Name
              </InputLabel>
              <TextField
                type="text"
                id="first-name"
                className="poc-input"
                disabled
                value={type === "edit" ? editParams.firstName : selectedPOC.firstName}
              />
            </div>
            <div className="input-field">
              <InputLabel
                className="label"
                htmlFor="last-name"
              >
                Last Name
              </InputLabel>
              <TextField
                type="text"
                id="last-name"
                className="poc-input"
                disabled
                value={type === "edit" ? editParams.lastName : selectedPOC.lastName}
              />
            </div>
          </div>
          <div className="email-phone">
            <div className="input-field">
              <InputLabel
                className="label"
                htmlFor="email"
              >
                Email
              </InputLabel>
              <TextField
                type="email"
                id="email"
                className="poc-input"
                disabled
                value={type === "edit" ? editParams.email : selectedPOC.email}
              />
            </div>
            <div className="input-field">
              <InputLabel
                className="label"
                htmlFor="phone"
              >
                Phone
              </InputLabel>
              <TextField
                type="text"
                id="phone"
                className="poc-input"
                disabled
                value={type === "edit" ? editParams.phone : selectedPOC.phone}
              />
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          className="poc-modal-btn"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        {type === "edit" ? (
          <Button
            className="poc-modal-btn"
            onClick={(e) => handleSubmit(e, "edit")}
          >
            Confirm
          </Button>
        ) : (
          <Button
            className="poc-modal-btn"
            onClick={(e) => handleSubmit(e, "assign")}
          >
            Assign
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default AddOrganizationPOCModal
