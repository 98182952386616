import { Box, Button, Divider, Typography } from "@mui/material"

// ICONS
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import IosShareIcon from "@mui/icons-material/IosShare"
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"

// STYLES
import "./styles/ProduceReportViewStyles.css"

const ProduceReportView = () => {
  return (
    // VIEW CONTAINER
    <Box
      style={{
        display: "flex",
        minWidth: "100%",
      }}
    >
      {/* GENERATE PDF CONTAINER */}
      <Box className="container">
        <Typography
          variant="stepTitle"
          sx={{}}
        >
          Generate PDF
        </Typography>
        <Box className="text-container">
          <Typography sx={{ margin: "2% 0% 0% 0%" }}>
            Select this option to create a standard report. This option will generate a PDF with applicable tool information for saving, printing, and
            attaching to the annual SAPBR submission.{" "}
          </Typography>
        </Box>
        <Box className="btn-container">
          <Button
            variant="contained"
            color="secondary"
            endIcon={<ArticleOutlinedIcon />}
            // endIcon={<FileDownloadIcon />}
            sx={{ width: "200px", borderRadius: "50px" }}
          >
            Generate PDF
          </Button>
        </Box>
      </Box>

      <Divider
        orientation="vertical"
        flexItem
      />

      {/* EXPORT LOG CONTAINER */}
      <Box className="container">
        <Typography
          variant="stepTitle"
          sx={{}}
        >
          Export Log
        </Typography>
        <Box className="text-container">
          <Typography sx={{ margin: "2% 0% 0% 0%" }}>
            Select this option to create an input report. This option will generate a data file for sharing that can be used for the “Import Log”
            procedure.
          </Typography>
        </Box>
        <Box className="btn-container">
          <Button
            variant="contained"
            color="secondary"
            endIcon={<IosShareIcon />}
            sx={{
              width: "200px",
              borderRadius: "50px",
              // bgcolor: "primary.dark"
            }}
          >
            Export Log
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ProduceReportView
