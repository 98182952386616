import { TextField } from "@mui/material"

const ActiveWorkFields = ({ data, estimateView }) => {
  return (
    <>
      <TextField
        size={estimateView && "small"}
        label="Cases In Development"
        value={data[0]?.casesInDevelopment || 0}
        disabled
        sx={{
          mb: "15px",
        }}
      />
      <TextField
        size={estimateView && "small"}
        label="Cases In Execution"
        value={data[0]?.casesInExecution || 0}
        disabled
        sx={{
          mb: "15px",
        }}
      />
      <TextField
        size={estimateView && "small"}
        label="Lines In Execution"
        value={data[0]?.linesInExecution || 0}
        disabled
        sx={{
          mb: "15px",
        }}
      />
      <TextField
        size={estimateView && "small"}
        label="Cases In Closure"
        value={data[0]?.casesInClosure || 0}
        disabled
      />
    </>
  )
}

export default ActiveWorkFields
