import { Box, Divider, TextField, Typography } from "@mui/material"
import Grid from "@mui/material/Grid2"

// Styles
import "./styles/EstimateWorkloadViewStyles.css"
import DashboardTable from "./elements/DashboardTable"
import ActiveWorkFields from "./elements/ActiveWorkFields"

const EstimateWorkloadView = ({ workloadCountsData }) => {
  const Thing = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <ActiveWorkFields
          data={workloadCountsData}
          estimateView
        />

        <Legend />
      </Box>
    )
  }

  return (
    <Box sx={{ flexGrow: 1, width: "100%", height: "100%", marginTop: "10px" }}>
      <Box sx={{ height: "100%", display: "grid", gridTemplateColumns: "1.5fr 2fr 2fr", gridTemplateRows: "75vh ", columnGap: 1, rowGap: 1 }}>
        <GridItem
          label="Active Work Last Day FY"
          children={<Thing />}
        />
        <GridItem
          size={5}
          label={"Projected New Work in FY"}
          children={
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <DashboardTable />
              <GridItem label="LORs for LOA Projected in FY23">
                <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                  <TextField
                    value={0}
                    sx={{ maxWidth: "350px" }}
                  />
                </Box>
              </GridItem>
            </Box>
          }
        />
        <GridItem
          size={5}
          label={"Forecasted Total Active Workload"}
          children={<DashboardTable />}
        />
      </Box>
    </Box>
  )
}

export default EstimateWorkloadView

const GridItem = ({ size, label, children, className }) => {
  return (
    <Grid
      className={`${className} gridItem`}
      size={size}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {label && (
          <Typography
            className="gridItemLabel"
            sx={{ fontWeight: 600 }}
          >
            {label}
          </Typography>
        )}
        {children}
      </Box>
    </Grid>
  )
}

const Legend = () => {
  return (
    <GridItem
      label="Legend"
      className="legendContainer"
    >
      <Box sx={{ display: "grid", gridTemplateColumns: "2fr 1fr 1fr 1fr", gridTemplateRows: "4vh 4vh 4vh 10vh", rowGap: 1 }}>
        <Typography variant="legendHeader">Trending Up</Typography>
        <Box className="legendBox legendLight"></Box>
        <Box className="legendBo legendMed"></Box>
        <Box className="legendBox legendDark"></Box>

        <Typography variant="legendHeader">Trending Down</Typography>
        <Box className="legendBox legendDark"></Box>
        <Box className="legendBo legendMed"></Box>
        <Box className="legendBox legendLight"></Box>

        <Typography variant="legendHeader">Manually Altered</Typography>
        <Box className="legendBox legendAltered"></Box>
        <Box className=""></Box>
        <Box className=""></Box>

        <Typography variant="legendHeader">Time Span</Typography>
        <Box sx={{ gridColumn: " 2/ span 3", minWidth: "150px" }}>
          <Box className="">New Work in the FY</Box>
          <Box className="">Total Active Workload</Box>
          <Box className="redText">Cumulative Total Work</Box>
        </Box>
      </Box>
    </GridItem>
  )
}
