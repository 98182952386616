import React, { useState, useEffect, useRef, useContext } from "react"
import { useLocation } from "react-router-dom"
import ACC_Utils from "./ACC_Utils"
import Papa from "papaparse"
import { apiCalls } from "../../DataService"
import {
  SubmitStatus,
  getQueryParamsOnTableUpdateFromDB,
  updateMUITableWithWarningsErrorsRows,
  formatHeader,
  CSVBulkUploadReqHeaders,
  getWarningsErrorsTableRowsFromUploadedTableRows,
  UploadSubmitEnabledStatuses,
  returnErrorMessageFromAPIError,
  CSVUploadProgressMessageTypes,
} from "../../../Utils"
import ACC_Instructions_Modal from "./ACC_Instructions_Modal"
import ReviewWarningsModal from "../ReviewWarningsModal"
import UploadProgressModal from "../UploadProgressModal"
import { SubmitRFIWarningModal } from "../SubmitRFIWarningModal"
import SnackbarMessages from "../../SnackbarMessages"
import { RequestResubmissionWarningModal } from "../RequestResubmissionWarningModal"
import AppContext from "../../../AppContext"
import { Box, Button, Stack, Typography } from "@mui/material"
import { Help } from "@mui/icons-material"
import RfiStatusChip from "../../custom/RfiStatusChip"
import CustomDataGrid from "../../elements/custom-grid/CustomDataGrid"
import RfiDataGrid from "../../custom/RfiDataGrid"

const ACC = () => {
  // Initialize App-level Data
  const context = useContext(AppContext)
  const { selectedOrg, activeFiscalCycle, filterOrganizations } = context

  useEffect(() => {
    filterOrganizations(["ACC"])
  }, [])

  const [selectedRFI, setSelectedRFI] = useState({})
  const [canUploadCSVACCData, setCanUploadCSVACCData] = useState(false)
  const [canSubmitRFIACCData, setCanSubmitRFIACCData] = useState(false)
  const [canExportRFIACCData, setCanExportRFIACCData] = useState(false)
  const [ACCDataSubmitStatus, setACCDataSubmitStatus] = useState(SubmitStatus.NOT_SUBMITTED)
  const [ACCDataTableRows, setACCDataTableRows] = useState({ rows:[], totalCount:0 })
  const [uploadedTableRows, setUploadedTableRows] = useState({ rows:[], totalCount:0 })
  const renderingDBRows = useRef(true)
  const [showRequestResubmissionWarningModal, setShowRequestResubmissionWarningModal] = useState(false)

  const matchedHeaderCount = useRef(0)
  const pageNumber = useRef(0)

  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState(false)
  const [showSnackbarError, setShowSnackbarError] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showSubmitRFIWarningModal, setShowSubmitRFIWarningModal] = useState(false)
  const [showUploadProgressModal, setShowUploadProgressModal] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState("")

  const [disclaimerRFIModalOpen, setDisclaimerRFIModalOpen] = useState(false)

  const fileSelector = useRef(null)
  const [fileSize, setFileSize] = useState(0)
  const [uploadCompleted, setUploadCompleted] = useState(false)
  const onlyWarningsInUpload = useRef(false)
  
  const getSelectedRFI = async () => {
    const RFI = await apiCalls.getAll(ACC_Utils.UrlApiTypes.rfi, { '$expand': 'fiscalCycle', '$filter': 'fiscalCycle/dataUpdateStatus eq 1' })
    if (RFI.length == 0) {
      setSelectedRFI({})
      return
    }
    setSelectedRFI(RFI)
    pageNumber.current = 0
  }

  const getTableSubmitStatus = async () => {
    setACCDataSubmitStatus(selectedRFI?.status ? selectedRFI.status : SubmitStatus.NOT_SUBMITTED)
    setCanSubmitRFIACCData(
      (renderingDBRows.current || onlyWarningsInUpload.current) &&
        (!selectedRFI?.status || UploadSubmitEnabledStatuses.includes(selectedRFI?.status)) &&
        ACCDataTableRows.length > 0
        ? true
        : false
    )
    setCanUploadCSVACCData(!selectedRFI?.status || UploadSubmitEnabledStatuses.includes(selectedRFI?.status) ? true : false)
    setCanExportRFIACCData(() => ACCDataTableRows.length > 0)
  }

  // Overwrite any existing csv entries in the DB with the new data
  const submitRFIData = () => {
    setShowBusyDialog(true)
    apiCalls
      .put(`${ACC_Utils.UrlApiTypes.rfi}/${selectedRFI.id}`, { ...selectedRFI, status: SubmitStatus.SUBMITTED })
      .then(() => {
        getSelectedRFI()
        setShowSnackbarSuccess(true)
        setSnackbarMessage("RFI Submitted Successfully!")
      })
      .catch(() => {
        setShowSnackbarError(true)
        setSnackbarMessage("Failed to submit RFI data")
      })
      .finally(() => setShowBusyDialog(false))
  }

  const handleLoadCSVDataIntoTable = (data) => {
    pageNumber.current = 0
    renderingDBRows.current = true
    setUploadedTableRows(data)
    setCanSubmitRFIACCData(renderingDBRows.current)
  }

  const madeFirstSubmissionOfSession = useRef(false)
  const headerRowNumber = useRef(0)
  const handleReadCSVFile = (file) => {
    setFileSize(file?.size)
    // setShowingWarningErrorUpload(false)
    // setToggleWarningsErrors(false)
    onlyWarningsInUpload.current = false
    madeFirstSubmissionOfSession.current = true
    let tmpParsedRows = []
    let parsedHeaders = []
    let headerRowFound = false
    headerRowNumber.current = 0
    // Ignore all other file types that are not 'text/csv'
    if (file.type === "text/csv") {
      setLoadingTitle(CSVUploadProgressMessageTypes.PREPARE)
      setShowUploadProgressModal(true)
      setUploadCompleted(false)
      Papa.parse(file, {
        header: false,
        worker: true,
        delimiter: "",
        skipEmptyLines: true,
        step: (row) => {
          if (!headerRowFound) {
            headerRowNumber.current += 1
            // Find the header row
            parsedHeaders = row.data
              .map((header) => formatHeader(header)?.replace(/\s/g, ""))
              .filter((header) => header.toLowerCase() !== "fiscalyear")
            parsedHeaders.map((potentialHeader) => {
              for (let i = 0; i < ACC_Utils.ExpectedACCHeaders.length; i++) {
                if (ACC_Utils.ExpectedACCHeaders[i].toLowerCase() === potentialHeader.toLowerCase()) {
                  matchedHeaderCount.current += 1
                  if (matchedHeaderCount.current >= ACC_Utils.ExpectedACCHeaders.length) {
                    headerRowFound = true
                  }
                  break
                }
              }
            })
            matchedHeaderCount.current = 0
          } else {
            // Data Row
            let parsedRow = {}
            for (let i = 0; i < ACC_Utils.ExpectedACCHeaders.length; i++) {
              const fieldValue = row?.data[i] ? row.data[i].trim() : ""
              parsedRow[ACC_Utils.ExpectedACCHeaders[i]] = fieldValue === "#" ? null : fieldValue
            }
            tmpParsedRows.push(parsedRow)
          }
        },
        complete: () => {
          setTimeout(() => {
            const trimmedParsedRows = tmpParsedRows.filter((row) => Object.values(row).filter((value) => value !== "").length > 0)
            handleLoadCSVDataIntoTable(trimmedParsedRows)
            headerRowFound = false
          }, 1000)
        },
      })
    } else {
      setShowSnackbarError(true)
      setSnackbarMessage("Cannot upload data: The file type is not supported. Please upload a CSV file.")
    }
  }

  const requestResubmission = () => {
    setShowBusyDialog(true)
    apiCalls
      .put(`${ACC_Utils.UrlApiTypes.rfi}/${selectedRFI.id}`, { ...selectedRFI, status: SubmitStatus.REQUEST_RESUBMISSION })
      .then(() => {
        getSelectedRFI()
        setShowSnackbarSuccess(true)
        setSnackbarMessage("Resubmission requested for RFI!")
      })
      .catch(() => {
        setShowSnackbarError(true)
        setSnackbarMessage("Failed to request resubmission for RFI")
      })
      .finally(() => setShowBusyDialog(false))
  }


  useEffect(() => {
    getTableSubmitStatus()
  }, [ACCDataTableRows])

  useEffect(() => {
    // renderingDBRows.current = true
    // onlyWarningsInUpload.current = false
    //setShowingWarningErrorUpload(false)
    if (selectedOrg?.id) {
      getSelectedRFI()
    }
    //setMUISortModel([])
  }, [selectedOrg, activeFiscalCycle])

  return (
    <Box margin={2}>
      <Typography variant="h5">ACC RFI CSV Upload</Typography>
      <div className="--RFI-table-actions">

        {/************************************************ [RFI Instructions Modal] ************************************************/}
        {/* PUT RFI Instructions here */}
        <ACC_Instructions_Modal
          disclaimerRFIModalOpen={disclaimerRFIModalOpen}
          setDisclaimerRFIModalOpen={setDisclaimerRFIModalOpen}
        />
        <Button
          variant="outlined"
          color="info"
          startIcon={<Help />}
          onClick={() => setDisclaimerRFIModalOpen(true)}
        >
          RFI Instructions
        </Button>
        {/************************************** Import CSV file here ********************************************************/}
        <Stack
          direction="row"
          spacing={1}
        >
          <Button
            variant="outlined"
            onClick={() => fileSelector.current.click()}
          >
            UPLOAD CSV FILE
            <input
              type="file"
              ref={fileSelector}
              onChangeCapture={(e) => handleReadCSVFile(e.target.files[0])}
              hidden
            />
          </Button>
        </Stack>

        </div>
        {/* DataGrid */}
        <div style={{ minHeight: "500px", maxHeight: "75vh", width: "100%", display: "flex", flexDirection: "column" }}>
          <RfiDataGrid
            resource="AccWorkloads/Active"
            columns={ACC_Utils.ACC_COLS}
          />
        </div>
      <UploadProgressModal
        open={showUploadProgressModal}
        title={loadingTitle}
        setTitle={setLoadingTitle}
        fileSize={fileSize}
        uploadCompleted={uploadCompleted}
        trackProgress={true}
      />
      <SnackbarMessages
        showSnackbarSuccess={showSnackbarSuccess}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
        showSnackbarError={showSnackbarError}
        setShowSnackbarError={setShowSnackbarError}
        snackbarMessage={snackbarMessage}
      />
      <RequestResubmissionWarningModal
        open={showRequestResubmissionWarningModal}
        setOpen={setShowRequestResubmissionWarningModal}
        requestResubmission={requestResubmission}
      />
      <SubmitRFIWarningModal
        showSubmitRFIWarningModal={showSubmitRFIWarningModal}
        setShowSubmitRFIWarningModal={setShowSubmitRFIWarningModal}
        msg={`If you submit RFI data, you won't be able to reupload data unless an administrator puts the ACC table back to a "Not Submitted" state in the ${selectedOrg.name} organization.`}
        submitRFIData={submitRFIData}
      />
    </Box>
  )
}

export default ACC
