import { withStyles } from "@mui/styles"
import { Tooltip } from "@mui/material"
import { Warning, Cancel } from "@mui/icons-material"

const WarningsErrorsTooltip = withStyles({
  tooltip: {
    color: "red",
    backgroundColor: "white",
    fontSize: 12,
  },
})(Tooltip)

class ACC_Utils {
  static ACCDataFields = {
    LOCAL_CONTRACT_NUMBER: "localContractNumber",
    ACTIONS_AGAINST_A_CAR_COUNT: "numberOfActions",
    CAR_ISSUED_COUNT: "carIssuedCount",
  }

  static ACCCSVFileName = "AccWorkloads.csv"

  static UrlApiTypes = {
    record: "AccWorkloads",
    rfi: "AccWorkloadRfis",
    rfiId: "accWorkloadRfiId",
  }

  static ExpectedACCHeaders = [
    this.ACCDataFields.LOCAL_CONTRACT_NUMBER,
    this.ACCDataFields.ACTIONS_AGAINST_A_CAR_COUNT,
    this.ACCDataFields.CAR_ISSUED_COUNT,
  ]

  static NonNullableFields = {
    ACC_DATA: [this.ACCDataFields.LOCAL_CONTRACT_NUMBER, this.ACCDataFields.ACTIONS_AGAINST_A_CAR_COUNT, this.ACCDataFields.CAR_ISSUED_COUNT],
  }

  static DateFields = {
    ACC_DATA: [],
  }

  static NumericFields = {
    ACC_DATA: [this.ACCDataFields.ACTIONS_AGAINST_A_CAR_COUNT, this.ACCDataFields.CAR_ISSUED_COUNT],
  }

  static ACC_COLS = [
    {
      field: "id",
      headerName: "Id",
      filterable: false,
    },
    {
      field: this.ACCDataFields.LOCAL_CONTRACT_NUMBER,
      headerName: "Local Contract Number",
      flex: 2,
      minWidth: 250,
    },
    {
      field: this.ACCDataFields.ACTIONS_AGAINST_A_CAR_COUNT,
      headerName: "Actions Against a CAR Count",
      flex: 2,
      type: 'number',
      minWidth: 250,
    },
    {
      field: this.ACCDataFields.CAR_ISSUED_COUNT,
      headerName: "CAR Issued Count",
      flex: 2,
      type: 'number',
      minWidth: 250,
    },
    // {
    //   field: "Warnings_Errors",
    //   headerName: "Warnings/Errors",
    //   sortable: false,
    //   flex: 2,
    //   minWidth: 350,
    //   headerClassName: "--RFI-grid-warning-header",
    //   headerAlign: "center",
    //   renderCell: (params) => {
    //     if (params?.value) {
    //       let tooltipMsg = ""
    //       let lineNumber = null
    //       if (params?.value?.errors?.length > 0) {
    //         lineNumber = params?.value?.errors[0]?.rowNumber
    //         tooltipMsg += `Line Number ${lineNumber}) `
    //       } else if (params?.value?.warnings?.length > 0) {
    //         lineNumber = params?.value?.warnings[0]?.rowNumber
    //         tooltipMsg += `Line Number ${lineNumber}) `
    //       }
    //       params?.value?.errors?.forEach((error, index) => (tooltipMsg += `E${index + 1}) ${error.message}\n`))
    //       params?.value?.warnings?.forEach((warning, index) => (tooltipMsg += `W${index + 1}) ${warning.message}\n`))
    //       return (
    //         <WarningsErrorsTooltip
    //           title={tooltipMsg}
    //           placement="bottom"
    //         >
    //           <div className="--RFI-warning-errors-icons">
    //             {params?.value?.warnings?.length > 0 && <Warning />}
    //             {params?.value?.errors?.length > 0 && <Cancel />}
    //           </div>
    //         </WarningsErrorsTooltip>
    //       )
    //     }
    //     return null
    //   },
    // },
  ]
}

export default ACC_Utils
