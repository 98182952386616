import { useContext, useEffect, useState } from "react"

// MUI
import { Box, Button, Collapse, FormControl, InputLabel, MenuItem, Select, Stepper, Step, StepButton, StepLabel, Typography } from "@mui/material"

// Context
import AppContext from "../../AppContext"

// Views
import CalculateFTEView from "./CalculateFTEView"
import EstimateWorkloadView from "./EstimateWorkloadView"
import ProduceReportView from "./ProduceReportView"
import ViewWorkload from "./ViewWorkload"
import { apiCalls } from "../../components/DataService"
import { orgHierarchy } from "../../Utils"
const steps = ["View Workload", "Estimate Workload", "Calculate FTE", "Produce Report"]

const Dashboard = () => {
  // ** --------------***     [ ~ HOOKS ~ ]     ***-------------- **
  let context = useContext(AppContext)
  let { allFiscalCycles } = context
  const [selectedTier2Org, setSelectedTier2Org] = useState(orgHierarchy[0])
  const [selectedTier3Org, setSelectedTier3Org] = useState("")
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})

  const [testing, setTesting] = useState("")

  const getNum = (num) => {
    return Number(num.slice(2)) + 3
  }

  const sortedFC = allFiscalCycles.sort((itemA, itemB) => {
    return getNum(itemA.fiscalYear) - getNum(itemB.fiscalYear)
  })

  const getLocalStorageData = (params) => {
    return JSON.parse(localStorage.getItem(params))
  }

  const [selectedFY, setSelectedFY] = useState(getLocalStorageData("FY") || sortedFC[0])

  // ** --------------***     [ ~ EFFECTS ~ ]     ***-------------- **
  useEffect(() => {
    getOrgFromSession()
  }, [])

  useEffect(() => {
    if (selectedFY?.id) {
      fetchData()
    }
  }, [selectedTier2Org, selectedTier3Org, selectedFY])

  useEffect(() => {
    if (selectedTier2Org) {
      setSelectedTier3Org("")
    }
  }, [selectedTier2Org])

  // ** --------------***     [ ~ ! TESTING ! ~ ]     ***-------------- **
  const [workloadCountsData, setWorkloadCountsData] = useState("")

  const getRandomNum = () => {
    let max = 300
    let min = 0
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const getOrgFromSession = () => {
    let arr = []
    let localStorageData = getLocalStorageData("ORG")

    let tier2 = orgHierarchy.find((item) => {
      let tier3 = item.organizations.find((org) => org.organizationName === localStorageData.name)

      if (tier3) {
        tier3 && arr.push(tier3)
        tier3 && arr.push(item)
        return item
      }
    })
    setSelectedTier2Org(tier2)
  }

  const fetchData = async () => {
    let workloadCountsFetch = await apiCalls.getById("WorkloadCounts", selectedFY?.id)

    setTesting(workloadCountsFetch)
    let filteredData
    let dataObj

    if (selectedTier3Org?.id) {
      filteredData = workloadCountsFetch.filter((item) => item.organizationId === selectedTier3Org.id)
      dataObj = getCasesData(filteredData)
      setWorkloadCountsData([dataObj])
    }

    if (!selectedTier3Org?.id && selectedTier2Org) {
      let arr = []
      selectedTier2Org.organizations.forEach((item) => {
        return workloadCountsFetch.find((wlData) => {
          if (wlData.organizationId === item.id) {
            arr.push(wlData)
          }
        })
      })

      dataObj = getCasesData(arr)
      setWorkloadCountsData([dataObj])
    }
    return dataObj
  }

  const getCasesData = (paramsArr) => {
    let casesInDevelopment = 0
    let casesInExecution = 0
    let linesInExecution = 0
    let casesInClosure = 0

    paramsArr.forEach((item) => {
      let { workPackage, stock0 } = item

      if (workPackage === "Implemented Lines") {
        linesInExecution = stock0
      }

      if (workPackage === "IMP") {
        casesInExecution = stock0
      }

      if (workPackage === "Closing Cases") {
        casesInClosure = stock0
      }

      //TODO: fix calculations to include just LOAs
      if (workPackage === "LOA" || workPackage === "AMD" || workPackage === "MOD" || workPackage === "P&A") {
        casesInDevelopment += stock0
      }
    })

    return {
      id: getRandomNum(),
      casesInDevelopment,
      casesInExecution,
      linesInExecution,
      casesInClosure,
    }
  }

  // ** --------------***     [ ~ FUNCTIONS ~ ]     ***-------------- **
  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step) => () => {
    setActiveStep(step)
  }

  const handleComplete = () => {
    setCompleted({
      ...completed,
      [activeStep]: true,
    })
    handleNext()
  }

  const handleReset = () => {
    setActiveStep(0)
    setCompleted({})
  }

  // Dropdowns
  const handleTier2Change = (e) => {
    setSelectedTier3Org("")
    let tier2Org = orgHierarchy.find((item) => item.tier2 === e.target.innerText)
    setSelectedTier2Org(tier2Org)
  }

  const handleTier3Change = (e) => {
    let test = selectedTier2Org.organizations.find((item) => item.organizationName === e.target.innerText)
    setSelectedTier3Org(test)
  }

  const handleFYChange = (e) => {
    let selectedId = e.target.value
    setSelectedFY(sortedFC.find((item) => item.id === selectedId))
  }

  const OrgMenu = () => {
    let arr = orgHierarchy.map((item) => {
      let { tier2, organizations } = item
      return (
        <>
          <MenuItem
            value={item}
            onClick={(e) => handleTier2Change(e)}
            selected={selectedTier2Org?.["tier2"] === tier2}
            sx={{ fontWeight: "bold", padding: "8px 15px" }}
            divider
          >
            {tier2}
          </MenuItem>
          <Collapse in={selectedTier2Org.tier2 === tier2}>
            {organizations.map((subOrg) => {
              return (
                <MenuItem
                  key={subOrg.organizationId}
                  value={subOrg.organizationId}
                  onClick={(e) => handleTier3Change(e)}
                  selected={selectedTier3Org?.organizationName ? selectedTier3Org.organizationName === subOrg.organizationName : false}
                  sx={{ padding: "8px 15px" }}
                >
                  {subOrg.organizationName}
                </MenuItem>
              )
            })}
          </Collapse>
        </>
      )
    })
    return (
      <FormControl
        size="small"
        variant="outlined"
        sx={{ display: "flex", minWidth: "175px", marginRight: "15px", padding: "0px 0px" }}
      >
        <InputLabel>Organization</InputLabel>
        <Select
          label="Organization"
          value={selectedTier2Org}
          renderValue={(params) => (selectedTier3Org ? params.tier2 + " - " + selectedTier3Org.organizationName : params.tier2)}
        >
          {arr}
        </Select>
      </FormControl>
    )
  }

  const FYMenu = () => {
    let menuItems = sortedFC.map((item, index) => {
      let menuItem =
        index + 1 == sortedFC.length ? (
          <MenuItem
            value={item.id}
            sx={{ display: "flex", alignItems: "center", padding: "5px 100px 5px 25px" }}
          >
            {item.fiscalYear}
          </MenuItem>
        ) : (
          <MenuItem
            value={item.id}
            sx={{ display: "flex", alignItems: "center", padding: "5px 100px 5px 25px" }}
            divider
          >
            {item.fiscalYear}
          </MenuItem>
        )

      return menuItem
    })

    return (
      <FormControl
        size="small"
        variant="outlined"
        className="selectLabel"
        sx={{ display: "flex", minWidth: "175px", padding: "0px 0px" }}
      >
        <InputLabel>Fiscal Year</InputLabel>
        <Select
          label="Fiscal Year"
          value={selectedFY}
          renderValue={(params) => params.fiscalYear}
          onChange={handleFYChange}
          autoWidth
          sx={{
            ".MuiSelect-select": {},
          }}
        >
          {menuItems}
        </Select>
      </FormControl>
    )
  }

  // ** --------------***     [ ~ RENDER ~ ]     ***-------------- **
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {/* STEPPER */}
      <Stepper
        nonLinear
        activeStep={activeStep}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            completed={completed[index]}
          >
            {/* <StepLabel StepIconComponent={DoubleArrowIcon}>{label}</StepLabel> */}
            <StepButton
              disableRipple
              color="inherit"
              onClick={handleStep(index)}
            >
              <Typography variant="stepperLabel">{label}</Typography>

              {/* {label} */}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box style={{ display: "flex", flexDirection: "column", minWidth: "100%", height: "100%" }}>
        {/* STEPS */}
        {allStepsCompleted() ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <>
            {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>{steps[activeStep]}</Typography> */}

            {/* DROPDOWNS */}
            <Box sx={{ display: "flex", flexDirection: "column", minWidth: "20%", w: 100, mt: "29px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <OrgMenu />
                <FYMenu />
              </Box>
            </Box>

            {/* VIEWS */}
            {activeStep == 0 && (
              <ViewWorkload
                testing={testing}
                selectedTier2Org={selectedTier2Org}
                selectedTier3Org={selectedTier3Org}
                setSelectedTier3Org={setSelectedTier3Org}
                setSelectedTier2Org={setSelectedTier2Org}
                selectedFY={selectedFY}
                workloadCountsData={workloadCountsData}
              />
            )}
            {activeStep == 1 && <EstimateWorkloadView workloadCountsData={workloadCountsData} />}
            {activeStep == 2 && <CalculateFTEView />}

            {activeStep == 3 && <ProduceReportView />}

            {/* BUTTONS */}
            {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                onClick={handleNext}
                sx={{ mr: 1 }}
                disableRipple
              >
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  >
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <></>
                  <Button onClick={handleComplete}>{completedSteps() === totalSteps() - 1 ? "Finish" : "Complete Step"}</Button>
                ))}
            </Box> */}
          </>
        )}
      </Box>
    </Box>
  )
}

export default Dashboard
