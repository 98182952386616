import React, { useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import { apiCalls } from "../../DataService"
import { ActiveState } from "../../../Utils"
import { Close } from "@mui/icons-material"
import { AddFCConfirmation } from "./AddFCConfirmation"
import { TextField, Dialog, DialogTitle, DialogActions, DialogContent, Button, Select, MenuItem, OutlinedInput, InputLabel } from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
  },
  formFields: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
  },
  title: {
    textAlign: "center",
  },
  select: {},
})

export const NewFiscalCycle = ({
  isOpen,
  toggleModal,
  loadFiscalCyclesFromDB,
  fiscalCycleTableRows,
  setSnackbarMessage,
  setShowSnackbarError,
  setShowSnackbarSuccess,
}) => {
  const classes = useStyles()
  const [formInput, setFormInput] = useState({
    year: "",
    startDate: "",
    endDate: "",
  })
  const [addFCConfirmationOpen, setAddFCConfirmationOpen] = useState(false)

  const handleCreateFiscalCycle = () => {
    if (formInput.year.length > 0) {
      const body = {
        startDate: formInput.startDate,
        endDate: formInput.endDate,
        fiscalYear: formInput.year,
        dataUpdateStatus: ActiveState.INACTIVE,
      }
      // Make POST or PUT DB call to add or edit a POC
      apiCalls
        .post(`FiscalCycles?defaultDates=false`, body)
        .then(() => {
          setShowSnackbarSuccess(true)
          setSnackbarMessage("Fiscal Cycle Created Successfully")
          loadFiscalCyclesFromDB()
        })
        .catch((e) => {
          setShowSnackbarError(true)
          setSnackbarMessage("Error Creating Fiscal Cycle")
          console.error(e)
        })
        .finally(() => {
          toggleModal()
          setFormInput({
            year: "",
            startDate: "",
            endDate: "",
          })
        })
    }
  }

  const handleFieldChange = (e, field) => {
    switch (field) {
      case "startDate":
        setFormInput({
          ...formInput,
          startDate: e.toDate().toISOString(),
        })
        break
      case "endDate":
        setFormInput({
          ...formInput,
          endDate: e.toDate().toISOString(),
        })
        break
      case "year":
        // const startDate = new Date(`${Number(e.target.value.substring(2, 4)) - 1 + 2000}-10-01T00:00:00`).toUTCString()
        // const endDate = new Date(`${Number(e.target.value.substring(2, 4)) + 2000}-09-30T23:59:59`).toUTCString()
        const startDate = new Date(Date.UTC(Number(e.target.value.substring(2, 4)) - 1 + 2000, 9, 1, 0, 0, 0)).toISOString()
        const endDate = new Date(Date.UTC(Number(e.target.value.substring(2, 4)) + 2000, 8, 30, 23, 59, 59)).toISOString()
        setFormInput({
          year: e.target.value,
          startDate,
          endDate,
        })
        break
      default:
        break
    }
  }

  const handleCancel = (e) => {
    e.preventDefault()
    setFormInput({
      year: "",
      startDate: "",
      endDate: "",
    })
    toggleModal()
  }

  useEffect(() => {
    setFormInput({
      year: "",
      startDate: "",
      endDate: "",
    })
  }, [])

  // TODO: move all the styling to a css file
  return (
    <div>
      <Dialog
        open={Boolean(isOpen)}
        onClose={handleCancel}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          className={classes.title}
          id="new-fc-dialog-title"
        >
          New Fiscal Cycle
        </DialogTitle>
        <DialogContent dividers>
          <form className={classes.form}>
            <div className={classes.formFields}>
              <div
                hidden={!formInput.year}
                style={{ margin: "0 1rem" }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Start Date"
                    value={dayjs(formInput.startDate)}
                    onChange={(newValue) => handleFieldChange(newValue, "startDate")}
                    renderInput={(params) => <TextField {...params} />}
                    timezone="UTC"
                  />
                </LocalizationProvider>
              </div>
              <div style={{ margin: "2rem" }}>
                <InputLabel id="select">Select Fiscal Cycle</InputLabel>
                <Select
                  className="poc-input"
                  onChange={(e) => handleFieldChange(e, "year")}
                  label="Select"
                >
                  <option
                    value="select-role"
                    hidden
                  >
                    Select
                  </option>
                  {Array(20)
                    .fill(0)
                    .map((year, idx) => idx)
                    .filter((year) => !fiscalCycleTableRows.map((row) => row.fiscalYear).includes(`FY${year + 20}`))
                    .map((year, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          value={`FY${year + 20}`}
                        >{`FY${year + 20}`}</MenuItem>
                      )
                    })}
                </Select>
              </div>
              <div
                hidden={!formInput.year}
                style={{ margin: "0 1rem" }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="End Date"
                    value={dayjs(formInput.endDate)}
                    onChange={(newValue) => handleFieldChange(newValue, "endDate")}
                    renderInput={(params) => <TextField {...params} />}
                    timezone="UTC"
                  />
                </LocalizationProvider>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            disabled={formInput.year <= 0}
            onClick={(e) => {
              const fiscalYear = Number(formInput?.year.slice(2, 4)) + 2000
              const endDateYear = Number(formInput?.endDate.slice(0, 4))
              if (formInput?.endDate < formInput?.startDate) {
                setShowSnackbarError(true)
                setSnackbarMessage("The Start Date must be before the End Date")
                handleCancel(e)
              } else if (fiscalYear !== endDateYear) {
                setShowSnackbarError(true)
                setSnackbarMessage("The End date must be within the calendar year of its fiscal year (e.g. the end date of FY23 must be in 2023)")
                handleCancel(e)
              } else {
                e.preventDefault()
                setAddFCConfirmationOpen(true)
              }
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <AddFCConfirmation
        open={addFCConfirmationOpen}
        setOpen={setAddFCConfirmationOpen}
        formInput={formInput}
        handleCreateFiscalCycle={handleCreateFiscalCycle}
      />
    </div>
  )
}
