import { useEffect } from "react"
import { BarChart } from "@mui/x-charts/BarChart"
import { apiCalls } from "../../../components/DataService"

const getRandomNum = () => {
  let max = 100
  let min = 0
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const seriesA = {
  data: [2, 3, 1, 4, 5],
  label: "P&As",
}
const seriesB = {
  data: [3, 1, 4, 2, 1],
  label: "LOR",
}
const seriesC = {
  data: [3, 2, 4, 5, 1],
  label: "AMD",
}
const seriesD = {
  data: [4, 1, 5, 2, 3],
  label: "MOD",
}

const CustomStackedBarChart = ({}) => {
  return (
    <BarChart
      width={600}
      height={300}
      xAxis={[{ data: ["FY21", "FY22", "FY23"], scaleType: "band" }]}
      series={[
        { ...seriesA, stack: "total" },
        { ...seriesB, stack: "total" },
        { ...seriesC, stack: "total" },
        { ...seriesD, stack: "total" },
      ]}
    />
  )
}

export default CustomStackedBarChart
