import ConfigData from "../config.json"
import axios from "axios"

const BASE_URL = ConfigData.BASE_URL
const ODATA = ConfigData.ODATA

const addQueryParam = (paramType, paramValue, firstQueryInURL) => {
  if (firstQueryInURL) {
    return `&%24${paramType}=${paramValue}`
  }
  return `?%24${paramType}=${paramValue}`
}

const addQueryParams = (url, queryParams) => {
  let tmpUrl = url
  let firstQueryInURL = false
  if (queryParams?.[QueryParamTypes.TOP]) {
    tmpUrl += addQueryParam(QueryParamTypes.TOP, queryParams[QueryParamTypes.TOP], firstQueryInURL)
    firstQueryInURL = true
  }
  if (queryParams?.[QueryParamTypes.SKIP]) {
    tmpUrl += addQueryParam(QueryParamTypes.SKIP, queryParams[QueryParamTypes.SKIP], firstQueryInURL)
    firstQueryInURL = true
  }
  if (queryParams?.[QueryParamTypes.FILTER]) {
    tmpUrl += addQueryParam(QueryParamTypes.FILTER, queryParams[QueryParamTypes.FILTER], firstQueryInURL)
    firstQueryInURL = true
  }
  if (queryParams?.[QueryParamTypes.SELECT]) {
    tmpUrl += addQueryParam(QueryParamTypes.SELECT, queryParams[QueryParamTypes.SELECT], firstQueryInURL)
    firstQueryInURL = true
  }
  if (queryParams?.[QueryParamTypes.ORDER_BY]) {
    tmpUrl += addQueryParam(QueryParamTypes.ORDER_BY, queryParams[QueryParamTypes.ORDER_BY], firstQueryInURL)
    firstQueryInURL = true
  }
  if (queryParams?.[QueryParamTypes.EXPAND]) {
    tmpUrl += addQueryParam(QueryParamTypes.EXPAND, queryParams[QueryParamTypes.EXPAND], firstQueryInURL)
    firstQueryInURL = true
  }
  return tmpUrl
}

export const QueryParamTypes = {
  TOP: "top",
  SKIP: "skip",
  FILTER: "filter",
  SELECT: "select",
  ORDER_BY: "orderby",
  EXPAND: "expand",
}

// This method handles server-side pagination, sorting and filtering
// based on the MUI DataGrid's paginationModel, sortModel and filterModel properties.
const getAllServer = async (apiType, paginationModel=null, sortModel=null, filterModel=null, params={}) => {
  let url = `${BASE_URL}/${apiType}`
  
  // Always add total count to the response
  params = { "$count": "true", ...params };
  
  // Add paging capability
  if(paginationModel) {
    params = { "$top": paginationModel.pageSize, "$skip": paginationModel.pageSize * paginationModel.page, ...params}
  }

  // Add sorting capability
  if(sortModel) {
    for (const sort of sortModel) {
      let orderBy = sort.field + " " + sort.sort;
      params = { "$orderBy": orderBy, ...params }
    }
  }

  // Add filtering capability
  if(filterModel?.items) {
    for(const filter of filterModel.items) {
      if(filter.value) {
        // TODO: There's probably a better way to map these (using .map()?)
        switch(filter.operator) 
        {
          // String operators
          case 'equals':
            params = { "$filter": `${filter.field} eq '${filter.value}'`, ...params }
            break;
          case 'doesNotEqual':
            params = { "$filter": `${filter.field} ne '${filter.value}'`, ...params }
            break;
          case 'contains':
            params = { "$filter": `contains(${filter.field}, '${filter.value}')`, ...params}
            break;
          case 'doesNotContain':
            params = { "$filter": `not(contains(${filter.field}, '${filter.value}'))`, ...params}
            break;
          case 'startsWith':
            params = { "$filter": `startsWith(${filter.field}, '${filter.value}')`, ...params}
            break;
          case 'endsWith':
            params = { "$filter": `endsWith(${filter.field}, '${filter.value}')`, ...params}
            break;
          case 'isEmpty':
            params = { "$filter": `${filter.field} eq null`, ...params }
            break;
          case 'isNotEmpty':
            params = { "$filter": `not(${filter.field} eq null)`, ...params }
            break;
          // Number operators
          case '=':
            params = { "$filter": `${filter.field} eq ${filter.value}`, ...params }
            break;
          case '!=':
            params = { "$filter": `${filter.field} ne ${filter.value}`, ...params }
            break;
          case '>':
            params = { "$filter": `${filter.field} gt ${filter.value}`, ...params }
            break;
          case '>=':
            params = { "$filter": `${filter.field} ge ${filter.value}`, ...params }
            break;  
          case '<':
            params = { "$filter": `${filter.field} lt ${filter.value}`, ...params }
            break;
          case '<=':
            params = { "$filter": `${filter.field} le ${filter.value}`, ...params }
            break;
          default:
            //params = {}
        }
      }
    }
  }

  let response = await axios.get(url, { params: params });

  //return the response data itself and the total count
  return  { rows: response.data, totalCount: response.headers["x-total-count"] };
}

/**
 * @deprecated This call should not be made on it's own.  Instead use inline count
 * @param {*} apiType 
 * @param {*} queryParams 
 * @returns 
 */
const axiosGetAllCount = (apiType, queryParams) => {
  let url = addQueryParams(`${BASE_URL}/${apiType}`, queryParams)
  return axios.get(url).then((res) => res.data)
}

const axiosGetAll = (apiType, queryParams) => {
  let url = addQueryParams(`${BASE_URL}/${apiType}`, queryParams)
  return axios.get(url).then((res) => res.data)
}

const fetchData = (apiType, query) => {
  let url = query ? `${BASE_URL}/${apiType}/${query}` : `${BASE_URL}/${apiType}`
  return axios.get(url)
}

const axiosGetById = (apiType, id) => {
  if (id) {
    return axios.get(`${BASE_URL}/${apiType}/${id}`).then((res) => res.data)
  }
  return axios.get(`${BASE_URL}/${apiType}`).then((res) => res.data)
}

//
const testPost = (url, body) => {
  return axios.post(`${BASE_URL}/${url}`, body)
}

const testPut = (url, body, id) => {
  if (id) {
    return axios.put(`${BASE_URL}/${url}/${id}`, body)
  }
}

const cleanPut = async (url, body, id) => {
  if (id) {
    return axios.put(`${BASE_URL}/${url}/${id}`, body)
  }
};

const testDelete = (url, id) => {
  return axios.delete(`${BASE_URL}/${url}/${id}`)
}
//

const axiosPost = (url, data, reqHeaders) => {
  if (data) {
    if (reqHeaders) return axios.post(`${BASE_URL}/${url}`, data, reqHeaders)
    else return axios.post(`${BASE_URL}/${url}`, data)
  }
  return axios.post(`${BASE_URL}/${url}`)
}

const axiosPut = (url, data) => {
  if (data) {
    return axios.put(`${BASE_URL}/${url}`, data)
  }
  return axios.put(`${BASE_URL}/${url}`)
}

const axiosPatch = (apiType, id, data) => {
  return axios.put(`${BASE_URL}/${apiType}/${id}`, data)
}

const axiosDelete = (url) => {
  return axios.delete(`${BASE_URL}/${url}`)
}

export const apiCalls = {
  testDelete,
  testPut,
  testPost,
  cleanPut,
  getAllCount: axiosGetAllCount,
  getAll: axiosGetAll,
  getAllServer: getAllServer,
  getById: axiosGetById,
  post: axiosPost,
  put: axiosPut,
  patch: axiosPatch,
  delete: axiosDelete,
  fetchData,
}
