import React, { useEffect, useRef, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { UserFields, AllOrganizationRoles, OrganizationRoles, AllGlobalRoles } from "../../../Utils"
import { apiCalls, QueryParamTypes } from "../../DataService"
import { useMutation } from "@tanstack/react-query"

const NewUser = ({
  userPocInput,
  setUserPocInput,
  isEditModalOpen,
  editParams,
  userList,
  pocList,
  selectedUserId,
  getAllUsers,
  toggleEditModal,
  setShowSnackbarError,
  setShowSnackbarSuccess,
  setSnackbarMessage,
  refetch,
  getRows,
  refresh,
}) => {
  const [orgs, setOrgs] = useState([])
  const [assignedOrg, setAssignedOrg] = useState({})
  const correspondingPoc = useRef({})
  const [assignedOrgRole, setAssignedOrgRole] = useState(OrganizationRoles.VIEW_ONLY)
  const [globalRoleToAdd, setGlobalRoleToAdd] = useState("")
  const [globalRoleToRemove, setGlobalRoleToRemove] = useState("")
  const [userGlobalRoles, setUserGlobalRoles] = useState([])
  const selectedUser = useRef({})

  const clearLocalData = () => {
    setAssignedOrg({})
    setAssignedOrgRole(OrganizationRoles.VIEW_ONLY)
    setGlobalRoleToAdd("")
    setGlobalRoleToRemove("")
  }

  const handleCancel = () => {
    setUserPocInput({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      disabled: false,
    })
    clearLocalData()
    toggleEditModal()
  }

  const handleChange = (e, type) => {
    switch (type) {
      case UserFields.FIRST_NAME:
        setUserPocInput({ ...userPocInput, firstName: e.target.value })
        break
      case UserFields.LAST_NAME:
        setUserPocInput({ ...userPocInput, lastName: e.target.value })
        break
      case UserFields.EMAIL:
        setUserPocInput({ ...userPocInput, email: e.target.value })
        break
      case UserFields.PHONE:
        setUserPocInput({ ...userPocInput, phone: e.target.value })
        break
      case UserFields.DISABLED:
        setShowSnackbarSuccess(true)
        if (userPocInput.disabled) {
          setSnackbarMessage("User set to be enabled on update")
        } else {
          setSnackbarMessage("User set to be disabled on update")
        }
        setUserPocInput((prevState) => {
          return { ...prevState, disabled: !userPocInput.disabled }
        })
        break
      case UserFields.ADD_GLOBAL_ROLE:
        setGlobalRoleToAdd(e.target.value)
        break
      case UserFields.REMOVE_GLOBAL_ROLE:
        setGlobalRoleToRemove(e.target.value)
        break
      case UserFields.ASSIGN_ORGANIZATION:
        setAssignedOrg(orgs.find((org) => org.name === e.target.value))
        break
      case UserFields.ASSIGN_ORGANIZATION_ROLE:
        setAssignedOrgRole(e.target.value)
        break
      default:
        break
    }
  }

  const handleConfirmEdit = async () => {
    // Update User in DB
    const tmpSelectedUser = userList.find((user) => user.id === selectedUserId)
    selectedUser.current = { ...tmpSelectedUser, email: userPocInput.email, phone: userPocInput.phone }
    // Enable or Disable user
    if (!userPocInput.disabled) {
      await apiCalls
        .post(`Accounts/${selectedUserId}/Enable`, null)
        .then(() => {
          setShowSnackbarSuccess(true)
          setSnackbarMessage("User Enabled Successfully")
        })
        .catch((e) => {
          setShowSnackbarError(true)
          setSnackbarMessage("Unable to enable user")
          console.error(e)
        })
    } else {
      await apiCalls
        .post(`Accounts/${selectedUserId}/Disable`, null)
        .then(() => {
          setShowSnackbarSuccess(true)
          setSnackbarMessage("User Disabled Successfully")
        })
        .catch((e) => {
          setShowSnackbarError(true)
          setSnackbarMessage("Unable to disable user")
        })
    }
    // Add user to global role
    if (globalRoleToAdd) {
      await apiCalls.post(`Accounts/${selectedUserId}/Roles/${globalRoleToAdd}/Add`, null)
    }
    // Remove user from global role
    if (globalRoleToRemove) {
      await apiCalls.post(`Accounts/${selectedUserId}/Roles/${globalRoleToRemove}/Remove`, null)
    }
    await apiCalls.put(`Accounts/${selectedUserId}`, selectedUser.current)
    // Update Corresponding POC in DB
    correspondingPoc.current = {
      ...correspondingPoc.current,
      firstName: userPocInput.firstName,
      lastName: userPocInput.lastName,
      activeState: !userPocInput.disabled,
    }
    await apiCalls.put(`Pocs/${correspondingPoc.current.id}`, correspondingPoc.current)
    // Assign corresponding POC to assigned organization
    if (assignedOrg.id) {
      await apiCalls.post(`Organizations/${assignedOrg.id}/Pocs/${correspondingPoc.current.id}/${assignedOrgRole}`, null)
    }
    refresh()
    // getAllUsers()
    clearLocalData()
    toggleEditModal()
  }

  useEffect(() => {
    if (assignedOrg.pocList) {
      const existingPOCInOrg = assignedOrg.pocList.find((poc) => poc.userName === selectedUser.current.userName)
      if (existingPOCInOrg) {
        setAssignedOrgRole(existingPOCInOrg.role)
      }
    }
  }, [assignedOrg])

  useEffect(() => {
    const tmpSelectedUser = userList.find((user) => user.id === selectedUserId)
    selectedUser.current = tmpSelectedUser
    if (selectedUserId) {
      apiCalls.getById(`Accounts/${selectedUserId}/Roles`, null).then((data) => {
        const globalRoles = data.filter((role) => AllGlobalRoles.includes(role))
        setUserGlobalRoles(globalRoles)
      })
      const tmpCorrespondingPoc = pocList.find((poc) => poc.userName === selectedUser.current.userName)
      correspondingPoc.current = tmpCorrespondingPoc
    }
  }, [selectedUserId])

  useEffect(() => {
    apiCalls.getAll(`Organizations`, { [QueryParamTypes.ORDER_BY]: "name" }).then((data) => {
      setOrgs(data)
    })
  }, [])

  return (
    <Dialog
      open={isEditModalOpen}
      onCLose={handleCancel}
    >
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent dividers>
        <div className="user-form">
          <div className="user-fname-lname">
            <TextField
              type="text"
              id="first-name"
              label="First Name"
              className="poc-input"
              defaultValue={editParams.firstName}
              onChange={(e) => handleChange(e, UserFields.FIRST_NAME)}
            />
            <TextField
              type="text"
              id="last-name"
              label="Last Name"
              className="poc-input"
              defaultValue={editParams.lastName}
              onChange={(e) => handleChange(e, UserFields.LAST_NAME)}
            />
          </div>
          <div className="user-email-phone">
            <TextField
              type="email"
              id="email"
              label="Email"
              className="poc-input"
              defaultValue={editParams.email}
              onChange={(e) => handleChange(e, UserFields.EMAIL)}
            />
            <TextField
              type="string"
              id="phone"
              label="Phone"
              className="poc-input"
              defaultValue={editParams.phone}
              onChange={(e) => handleChange(e, UserFields.PHONE)}
            />
          </div>
          <div className="role-select-container">
            <div
              className="user-input-field"
              style={{ flex: 1 }}
            >
              <InputLabel
                className="label"
                htmlFor="role"
              >
                Add Global User Role
              </InputLabel>
              <Select
                name="roles"
                id="-roles"
                className="poc-input"
                onChange={(e) => handleChange(e, UserFields.ADD_GLOBAL_ROLE)}
              >
                {AllGlobalRoles.filter((role) => !userGlobalRoles.includes(role)).map((role, idx) => (
                  <MenuItem
                    key={idx}
                    value={role}
                  >
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div
              className="user-input-field"
              style={{ flex: 1 }}
            >
              <InputLabel
                className="label"
                htmlFor="role"
              >
                Remove Global User Role
              </InputLabel>
              <Select
                name="roles"
                id="-roles"
                className="poc-input"
                onChange={(e) => handleChange(e, UserFields.REMOVE_GLOBAL_ROLE)}
              >
                {userGlobalRoles.map((role, idx) => (
                  <MenuItem
                    key={idx}
                    value={role}
                  >
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className="role-select-container">
            <div
              className="user-input-field"
              style={{ flex: 1 }}
            >
              <InputLabel
                htmlFor="add-organization"
                className="label"
              >
                Assign to FMS Organization
              </InputLabel>
              <Select
                name="add-organization"
                id="add-user-org-dropdown-select-id"
                className="poc-input"
                onChange={(e) => handleChange(e, UserFields.ASSIGN_ORGANIZATION)}
              >
                {orgs &&
                  correspondingPoc?.current?.organizationList &&
                  orgs
                    .filter((org) => !correspondingPoc.current.organizationList.map((orgObj) => orgObj.organization.id).includes(org.id))
                    .map((org) => (
                      <MenuItem
                        key={org.id}
                        value={`${org.name}`}
                      >
                        {org.name}
                      </MenuItem>
                    ))}
              </Select>
            </div>
            {assignedOrg.name === undefined ? (
              <div />
            ) : (
              <div
                className="user-input-field"
                style={{ flex: 1 }}
              >
                <InputLabel
                  className="label"
                  htmlFor="add-org-role"
                >{`Assign to role in ${assignedOrg.name} organization`}</InputLabel>
                <Select
                  name="roles"
                  id="-roles"
                  value={assignedOrgRole}
                  className="poc-input"
                  onChange={(e) => handleChange(e, UserFields.ASSIGN_ORGANIZATION_ROLE)}
                >
                  {AllOrganizationRoles.map((role, idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        value={role}
                      >
                        {role}
                      </MenuItem>
                    )
                  })}
                </Select>
              </div>
            )}
          </div>
          <div className="disable-container">
            <input
              type="checkbox"
              checked={userPocInput.disabled}
              id="disable-user"
              name="disable-user"
              value="disable-user"
              className="disable-checkbox"
              onChange={(e) => handleChange(e, UserFields.DISABLED)}
            />
            <label
              htmlFor="disable-user"
              className="disable-checkbox-label"
            >
              Disable
            </label>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleConfirmEdit}>Update</Button>
      </DialogActions>
    </Dialog>
    // <Box>
    //   <Modal
    //     open={isEditModalOpen}
    //     sx={{
    //       display: "flex",
    //       justifyContent: "center",
    //       alignItems: "center",
    //     }}
    //   >
    //     <div className="user-modal-container">
    //       <div className="user-form">

    //
    //       </div>
    //       <div className="user-modal-buttons">
    //         <Button
    //           variant="outlined"
    //           onClick={handleCancel}
    //           className="user-modal-btn"
    //           sx={{ marginRight: "10px" }}
    //         >
    //           Cancel
    //         </Button>
    //         <Button
    //           variant="submitBtn"
    //           onClick={handleConfirmEdit}
    //           className="user-modal-btn"
    //         >
    //           Update
    //         </Button>
    //       </div>
    //     </div>
    //   </Modal>
    // </Box>
  )
}

export default NewUser
