import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid"

const CustomGridToolbar = ({ children, colSort }) => {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: "5px",
        paddingBottom: "5px",
        borderBottom: "1px solid rgb(224, 224, 224)",
      }}
    >
      <section>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </section>
      <section style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>{children}</section>
    </GridToolbarContainer>
  )
}

export default CustomGridToolbar
