// MUI
import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"

const dummyRows = ["P&As", "LORs for LOA", "AMDs", "MODs", "Implemented Lines", "Requisitions", "SDRs", "Closing Cases"]
const dummyColumnDefs = [
  {
    field: "FY24",
    headerName: "FY24",
    flex: 1,
  },
  {
    field: "FY25",
    headerName: "FY25",
    flex: 1,
  },
  {
    field: "FY26",
    headerName: "FY26",
    flex: 1,
  },
  {
    field: "wpView",
    headerName: "Work Package View",
    flex: 2,
  },
]

const getDataItem = (wpView) => {
  return {
    id: Math.floor(Math.random() * 10) + 1,
    FY24: Math.floor(Math.random() * 10) + 1,
    FY25: Math.floor(Math.random() * 10) + 1,
    FY26: Math.floor(Math.random() * 10) + 1,
    wpView: wpView,
  }
}
const rowData = dummyRows.map((item) => {
  return getDataItem(item)
})

const DashboardTable = () => {
  return (
    <Box className="gridItemChild ">
      <DataGrid
        density="compact"
        rows={rowData}
        columns={dummyColumnDefs}
        hideFooter
        sx={{
          height: "100%",
        }}
      />
    </Box>
  )
}

export default DashboardTable
