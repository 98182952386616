import { Box, Divider, Typography } from "@mui/material"
import DashboardLineChart from "./elements/DashboardLineChart"
import DashboardSimpleTable from "./elements/DashboardSimpleTable"

// Styles
import "./styles/CalculateFTEViewStyles.css"

const CalculateFTEView = () => {
  // LAST FY
  const columnDefs = [
    {
      field: "laborCategory",
      headerName: "Labor Category",
      flex: 2,
    },
    {
      field: "FY22",
      headerName: "FY22",
      flex: 1,
    },
  ]

  const rowFields = ["Civilians sans BPC", "Contractor sans BPC", "Military", "Unaccounted Civilian"]

  const lastFYRowData = rowFields.map((item, index) => {
    return {
      id: index,
      laborCategory: item,
      FY22: Math.floor(Math.random() * 10) + 1,
    }
  })

  // NEXT FY
  const columnDefsNextFY = [
    {
      field: "laborCategory",
      headerName: "Labor Category",
      flex: 2,
    },
    {
      field: "FY24",
      headerName: "FY24",
      flex: 1,
    },
    {
      field: "FY25",
      headerName: "FY25",
      flex: 1,
    },
    {
      field: "FY26",
      headerName: "FY26",
      flex: 1,
    },
  ]

  const nextFYRowData = rowFields.map((item, index) => {
    return {
      id: index,
      laborCategory: item,
      FY24: Math.floor(Math.random() * 10) + 1,
      FY25: Math.floor(Math.random() * 10) + 1,
      FY26: Math.floor(Math.random() * 10) + 1,
    }
  })

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      {/* LAST FY CONTAINER */}
      <Box className="sectionContainer">
        <Typography
          className="gridItemLabel"
          variant="stepTitle"
        >
          Last FY
        </Typography>
        <DashboardLineChart />
        <Box sx={{ display: "flex" }}>
          <DashboardSimpleTable
            rows={lastFYRowData}
            columnDefs={columnDefs}
          />
        </Box>
      </Box>

      <Divider
        orientation="vertical"
        flexItem
        sx={{ mr: 1, ml: 1 }}
      />

      {/* NEXT FY CONTAINER */}
      <Box className="sectionContainer">
        <Typography
          className="gridItemLabel"
          variant="stepTitle"
          sx={{}}
        >
          Next FY
        </Typography>
        <DummyForecastedChild />
        <Box sx={{ display: "flex" }}>
          <DashboardSimpleTable
            rows={nextFYRowData}
            columnDefs={columnDefsNextFY}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default CalculateFTEView

const DummyForecastedChild = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", mt: 10, mb: 21 }}>
      <Typography>Forecasted LORs for LOA</Typography>
      <Divider />
      <Typography>Recommended FTE</Typography>
    </Box>
  )
}
