import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"

const DashboardSimpleTable = ({ rows, columnDefs }) => {
  return (
    <DataGrid
      density="compact"
      rows={rows}
      columns={columnDefs}
      hideFooter
    />
  )
}

export default DashboardSimpleTable
