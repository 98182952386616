import { useState } from "react"

//Custom
import ActiveWorkFields from "./elements/ActiveWorkFields"
// import CustomTabPanel from "../../components/custom/CustomTabPanel"

// MUI
import { DataGrid } from "@mui/x-data-grid"
import { Box, Dialog, DialogContent, Tab, Tabs, Typography } from "@mui/material"
import CustomStackedBarChart from "./elements/CustomStackedBarChart"

const wpDetailsData = [
  { id: 1, caseID: "AL-B-PAB", caseDetail: 2 },
  { id: 2, caseID: "MX-B-PBS", caseDetail: 1 },
  { id: 3, caseID: "MX-B-PBS", caseDetail: 1 },

  { id: 4, caseID: "MX-B-PBS", caseDetail: 1 },
  { id: 5, caseID: "MX-B-PBS", caseDetail: 1 },
]

const ViewWorkload = ({ selectedFY, selectedTier3Org, workloadCountsData, testing }) => {
  // ** --------------***     [ ~ HOOKS ~ ]     ***-------------- **
  const [currentTab, setCurrentTab] = useState(1)
  const [currentDataTab, setCurrentDataTab] = useState(1)
  const [open, setOpen] = useState(false)

  const handleDataTabChange = async (e, newValue) => {
    setCurrentDataTab(newValue)
  }
  const wpDetailsCols = [
    {
      field: "caseID",
      headerName: "Case ID",
      headerClassName: "workloadCountsHeader",
      renderCell: (params) => {
        return (
          <div
            className="MuiDataGrid-cellContent"
            onClick={() => setOpen(true)}
          >
            {params.value}
          </div>
        )
      },
      flex: 1,
    },
    {
      field: "caseDetail",
      headerName: "Case Detail",
      headerClassName: "workloadCountsHeader",
      flex: 1,
    },
  ]

  // ** --------------***     [ ~ RENDER ~ ]     ***-------------- **
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", minHeight: "100%" }}>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{ minWidth: "1500px", padding: "50px" }}
      >
        <Box sx={{ minWidth: "600px", padding: "50px" }}>
          <DataGrid
            rows={wpDetailsData}
            columns={wpDetailsCols}
            hideFooter
            sx={{ width: "100%" }}
          />
        </Box>
      </Dialog>
      {/* WORKLOAD COUNTS CONTAINER */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          minWidth: "100%",
          w: 100,
        }}
      >
        {/* WORKLOAD COUNTS HEADER */}
        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "15px" }}>
          <Typography variant="dataGridTitle">Workload Counts</Typography>
        </Box>

        {/* WORKLOAD COUNTS */}
        <Box sx={{ display: "flex", justifyContent: "spacae-between", h: 100, minWidth: "100%" }}>
          {/*  WORKLOAD COUNTS CONTAINER */}
          {/* WORKLOAD COUNTS TEXTFIELD CONTAINER */}
          {workloadCountsData && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                minWidth: "30%",
                minHeight: "100%",
              }}
            >
              <ActiveWorkFields data={workloadCountsData} />
            </Box>
          )}

          {/* PIE CHART CONTAINER */}
          <Box
            sx={{
              display: "flex",
              border: "1px solid rgb(224, 224, 224)",
              borderRadius: "5px",
              width: "70%",
              justifyContent: "center",
              alignItems: "center",
              ml: 2,
            }}
          >
            <CustomStackedBarChart
              selectedFYId={selectedFY?.id}
              data={workloadCountsData[0]}
              testing={testing}
            />
          </Box>
        </Box>
        {/* </Box> */}

        {/* DATA GRID */}
        {selectedTier3Org && (
          <Box sx={{ height: "100%", width: "100%", mt: "45px" }}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "15px" }}>
              <Typography variant="dataGridTitle">Case and Work Package Details</Typography>
            </Box>

            {/* TODO: refactor */}
            <Tabs
              value={currentDataTab}
              onChange={handleDataTabChange}
              sx={{
                width: "100%",
                marginBottom: "0px",
                borderColor: "divider",
                marginTop: "10px",
                ".MuiTabs-flexContainer": {
                  justifyContent: "space-evenly",
                  overflow: "visible",
                  marginLeft: "0px",
                },
                ".MuiTabs-scrollableX": {
                  overflowX: "auto",
                },
                ".Mui-selected": {},
              }}
            >
              <Tab
                label="P&As"
                value={1}
                disableFocusRipple
                disableRipple
              />
              <Tab
                label="LOR"
                value={2}
                disableFocusRipple
                disableRipple
              />
              <Tab
                label="Amendments"
                value={3}
                disableFocusRipple
                disableRipple
              />
              <Tab
                label="Modifications"
                value={4}
                disableFocusRipple
                disableRipple
              />
              <Tab
                label="Implemented Lines"
                value={5}
                disableFocusRipple
                disableRipple
              />
              <Tab
                label="Requisitions"
                value={6}
                disableFocusRipple
                disableRipple
              />
              <Tab
                label="SDRs"
                value={7}
                disableFocusRipple
                disableRipple
              />
              <Tab
                label="Closing Cases"
                value={8}
                disableFocusRipple
                disableRipple
              />
            </Tabs>

            <Box
              sx={{
                display: "flex",
                maxWidth: "100%",
                width: "100%",
              }}
            >
              <DataGrid
                loading={!wpDetailsData}
                rows={wpDetailsData}
                columns={wpDetailsCols}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                hideFooter
                disableRowSelectionOnClick
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ViewWorkload
